<template>
	<v-row>
    <v-dialog fullscreen  v-model="dialogTelaCheia">
      <v-card color="blue-grey lighten-1" >
        <v-card-title></v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12" class="pa-0">
              <v-card class="primary pa-3 pt-0 white--text">
                <v-card-title class="py-3 px-1">
                  Monitoramento de Pré-Cargas
                  <v-spacer/>


                  <v-btn height="32" color="error" class="black--text mr-3" :class="dadosDivergencias.length>0 ? 'pulse-btn' : ''"  @click="listarDivergencias(), dialogDivergencias=true">
                    <v-icon size="32" class="mr-lg-2" :style="dadosDivergencias.length>0 ? iconStyle : ''">mdi-truck-alert</v-icon>
                    <v-divider class="d-none d-lg-block" vertical />
                    <v-badge color="red darken-3" :content="dadosDivergencias.length" :value="dadosDivergencias.length" offset-x="-5">
                      <span class="ml-2 d-none d-lg-block" :style="dadosDivergencias.length>0 ? iconStyle : ''">divergências</span>
                    </v-badge>
                  </v-btn>

                  <!--          <v-divider style="background-color: rgba(255,255,255,0.33)" vertical class="mx-4"/>-->
                  <v-btn height="32" class="mr-3 success black--text" @click="dialogEntregas=true">
                    <v-icon size="32" color="black" class="mr-lg-2">mdi-truck-check</v-icon>
                    <v-divider class="d-none d-lg-block" vertical />
                    <span class="ml-2 d-none d-lg-block">Confirmadas</span>
                  </v-btn>


                  <v-btn height="32" color="primary" class="rounded grey lighten-2 mr-3" @click="listarPreCargas(), listarDivergencias()">
                    <v-icon size="22" class="mr-lg-2">mdi-cached</v-icon>
                    <v-divider class="d-none d-lg-block" vertical />
                    <span class="ml-2 d-none d-lg-block">{{ countdownText }}</span>
                  </v-btn>
                  <v-btn height="32" color="primary" class="rounded grey lighten-2" @click="dialogTelaCheia=false">
                    <v-icon size="22" class="mr-lg-2">mdi-close</v-icon>
                    <v-divider class="d-none d-lg-block" vertical />
                    <span class="ml-2 d-none d-lg-block">fechar</span>
                  </v-btn>


                </v-card-title>
                <v-divider/>
                <v-card-text class="pa-3">
                  <v-row>
                    <v-col cols="12" class="pa-0 pb-2 d-flex flex-column">
                      <v-card light elevation="1" class="flex-grow-1" height="27vh">
                        <v-card-title class="py-2 pt-3">
                          <v-icon size="26" class="mr-3">mdi-map-clock</v-icon> Pendente
                        </v-card-title>
                        <v-divider/>

                        <v-card-text class="pa-5">
                          <v-row>
                            <v-col cols="12" v-if="!dadosPreCarga.Pendente">
                              <span class="mx-auto">Aguardando montagem de pré-carga CISSPoder.</span>
                            </v-col>
                            <v-col cols="12" xl="2" lg="2" md="3" sm="4" v-for="dado in dadosPreCarga.Pendente" :key="dado.idprecarga" class="pa-2">
                              <v-tooltip open-delay="50000" bottom color="white" content-class='custom-tooltip'>
                                <template v-slot:activator="{ on }">
                          <span v-on="on">
                            <v-card class="pa-3 progress-card" light @click="listarPedidos(dado)">
                              <v-progress-linear
                                  value="0"
                                  color="primary"
                                  class="progress-background"
                                  height="100%"
                              ></v-progress-linear>
                              <div class="progress-content">
                                <div class="truck-icon">
                                  <v-icon color="white">mdi-truck</v-icon>
                                  <h4> 0/{{ dado.qtdpedidos }}</h4>
                                </div>

                                <h3> ID: {{ dado.idprecarga }}</h3>
                                <h5> {{ dado.data }}</h5>
                              </div>
                            </v-card>
                          </span>
                                </template>
                                <v-card elevation="0" style="min-width: 360px" class="pa-1">
                                  <v-card flat class="primary" dark elevation="7">
                                    <v-card-title class="text-h6 py-2">Pedidos</v-card-title>
                                    <v-divider />
                                    <v-card-text class="white--text py-4">
                                      <v-row v-for="n in 10" :key="n" class="align-center">
                                        <v-col cols="12" md="6" class="py-1 pb-n1">
                                          <v-icon class="mr-1" size="17">mdi-alert-circle</v-icon>
                                          Pedido: {{ n * 120 }}
                                        </v-col>
                                        <v-col cols="12" md="6" class="py-1">
                                          <v-progress-linear
                                              :value="n * 8 + 3"
                                              color="red"
                                              class="ma-0"
                                              striped
                                              background-opacity="0.2"
                                              background-color="white"
                                              height="14px"
                                              rounded
                                          >
                                            <strong>{{ n * 8 + 3 }}%</strong>
                                          </v-progress-linear>
                                        </v-col>
                                      </v-row>
                                    </v-card-text>
                                  </v-card>
                                </v-card>
                              </v-tooltip>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-col>

                    <v-col cols="12" class="pa-0 py-1">
                      <v-card light elevation="1" height="27vh">
                        <v-card-title class="py-2 pt-3">
                          <v-icon size="26" class="mr-3">mdi-cog-play</v-icon> Produção
                        </v-card-title>
                        <v-divider/>

                        <v-card-text class="pa-5">
                          <v-row>
                            <v-col cols="12" v-if="!dadosPreCarga.Producao">
                              <span class="mx-auto">Nenhuma pré-carga em produção no momento.</span>
                            </v-col>
                            <v-col cols="12" xl="2" lg="2" md="3" sm="4" v-for="dado, n in dadosPreCarga.Producao" :key="dado.idprecarga" class="pa-2">
                              <v-tooltip open-delay="50000" bottom color="white" content-class='custom-tooltip'>
                                <template v-slot:activator="{ on }">
                          <span v-on="on">
                            <v-card class="pa-3 progress-card" light @click="listarPedidos(dado)">
                              <v-progress-linear
                                  :value="(n+1)*22"
                                  color="primary"
                                  class="progress-background"
                                  height="100%"
                              ></v-progress-linear>
                              <div class="progress-content">
                                <div class="truck-icon">
                                  <v-icon color="white">mdi-truck</v-icon>
                                  <h4>0/{{dado.qtdpedidos}}</h4>
                                </div>

                                <h3> ID: {{ dado.idprecarga }}</h3>
                                <h5> {{ dado.data }}</h5>
                              </div>
                            </v-card>
                          </span>
                                </template>
                                <v-card elevation="0" style="min-width: 360px" class="pa-1">
                                  <v-card flat class="primary" dark elevation="7">
                                    <v-card-title class="text-h6 py-2">Pedidos</v-card-title>
                                    <v-divider />
                                    <!--                    <v-card-text class="white&#45;&#45;text py-2">-->
                                    <!--                      <div :key="n" v-for="n in 10">-->
                                    <!--                        <div class="float-left">-->
                                    <!--                          <div class="py-1">-->
                                    <!--                            <v-icon class="mr-1" size="20">mdi-alert-outline</v-icon>Pedido: {{ n*120 }}-->
                                    <!--                          </div>-->
                                    <!--                        </div>-->
                                    <!--                        <div class="text-right">-->
                                    <!--                          <v-progress-linear :value="n*8+3" color="red" class="ma-0" striped background-opacity="0.2" background-color="white">-->
                                    <!--                            <strong>{{ n*8+3 }}%</strong>-->
                                    <!--                          </v-progress-linear>-->
                                    <!--                        </div>-->
                                    <!--                      </div>-->
                                    <!--                    </v-card-text>-->
                                    <v-card-text class="white--text py-4">
                                      <v-row v-for="n in 10" :key="n" class="align-center">
                                        <v-col cols="12" md="6" class="py-1 pb-n1">
                                          <v-icon class="mr-1" size="17">mdi-alert-circle</v-icon>
                                          Pedido: {{ n * 120 }}
                                        </v-col>
                                        <v-col cols="12" md="6" class="py-1">
                                          <v-progress-linear
                                              :value="n * 8 + 3"
                                              color="red"
                                              class="ma-0"
                                              striped
                                              background-opacity="0.2"
                                              background-color="white"
                                              height="14px"
                                              rounded
                                          >
                                            <strong>{{ n * 8 + 3 }}%</strong>
                                          </v-progress-linear>
                                        </v-col>
                                      </v-row>
                                    </v-card-text>
                                  </v-card>
                                </v-card>
                              </v-tooltip>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-col>

                    <v-col cols="12" class="pa-0 pt-2">
                      <v-card light elevation="1" height="27vh">
                        <v-card-title class="py-2 pt-3">
                          <v-icon size="26" class="mr-3">mdi-truck-cargo-container</v-icon> Carregado
                        </v-card-title>
                        <v-divider/>

                        <v-card-text class="pa-5">
                          <v-row>
                            <v-col cols="12" v-if="!dadosPreCarga.Produzida">
                              <span class="mx-auto">Nenhuma pré-carga carregada no momento.</span>
                            </v-col>
                            <v-col cols="12" xl="2" lg="2" md="3" sm="4" v-for="dado in dadosPreCarga.Produzida" :key="dado.idprecarga" class="pa-2">
                              <v-tooltip open-delay="50000" bottom color="white" content-class='custom-tooltip'>
                                <template v-slot:activator="{ on }">
                          <span v-on="on">
                            <v-card class="pa-3 progress-card" light @click="listarPedidos(dado)">
                              <v-progress-linear
                                  value="100"
                                  color="primary"
                                  class="progress-background"
                                  height="100%"
                              ></v-progress-linear>
                              <div class="progress-content">
                                <div class="truck-icon">
                                  <v-icon color="white">mdi-truck</v-icon>
                                  <h4>{{dado.qtdpedidos}}/{{dado.qtdpedidos}}</h4>
                                </div>

                                <h3> ID: {{ dado.idprecarga }}</h3>
                                <h5> {{ dado.data }}</h5>
                              </div>
                            </v-card>
                          </span>
                                </template>
                                <v-card elevation="0" style="min-width: 360px" class="pa-1">
                                  <v-card flat class="primary" dark elevation="7">
                                    <v-card-title class="text-h6 py-2">Pedidos</v-card-title>
                                    <v-divider />
                                    <!--                    <v-card-text class="white&#45;&#45;text py-2">-->
                                    <!--                      <div :key="n" v-for="n in 10">-->
                                    <!--                        <div class="float-left">-->
                                    <!--                          <div class="py-1">-->
                                    <!--                            <v-icon class="mr-1" size="20">mdi-alert-outline</v-icon>Pedido: {{ n*120 }}-->
                                    <!--                          </div>-->
                                    <!--                        </div>-->
                                    <!--                        <div class="text-right">-->
                                    <!--                          <v-progress-linear :value="n*8+3" color="red" class="ma-0" striped background-opacity="0.2" background-color="white">-->
                                    <!--                            <strong>{{ n*8+3 }}%</strong>-->
                                    <!--                          </v-progress-linear>-->
                                    <!--                        </div>-->
                                    <!--                      </div>-->
                                    <!--                    </v-card-text>-->
                                    <v-card-text class="white--text py-4">
                                      <v-row v-for="n in 10" :key="n" class="align-center">
                                        <v-col cols="12" md="6" class="py-1 pb-n1">
                                          <v-icon class="mr-1" size="17">mdi-alert-circle</v-icon>
                                          Pedido: {{ n * 120 }}
                                        </v-col>
                                        <v-col cols="12" md="6" class="py-1">
                                          <v-progress-linear
                                              :value="n * 8 + 3"
                                              color="red"
                                              class="ma-0"
                                              striped
                                              background-opacity="0.2"
                                              background-color="white"
                                              height="14px"
                                              rounded
                                          >
                                            <strong>{{ n * 8 + 3 }}%</strong>
                                          </v-progress-linear>
                                        </v-col>
                                      </v-row>
                                    </v-card-text>
                                  </v-card>
                                </v-card>
                              </v-tooltip>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog width="1280px" v-model="dialogPedidos" persistent scrollable>
      <v-card class="primary" dark>
        <v-card-title>
          <span class="mx-auto">Pré-Carga: {{tempPreCarga.idprecarga}}</span>
        </v-card-title>

        <v-card-text class="px-0 pb-0">
          <v-data-table
              light
              :headers="[
                { text: 'Empresa', value: 'idempresa', sortable: false, align: 'start', class:'pr-2' },
                { text: 'Pedido', value: 'pedido', sortable: false },
                { text: 'Código', value: 'idclifor', sortable: false  },
                { text: 'Cliente', value: 'cliente', sortable: false  },
                { text: '% Separado', value: 'percseparar', align:' text-center', sortable: false  },
                { text: '% Conferido', value: 'percconferido', align:' text-center', sortable: false  },
                { text: '% Carregado', value: 'perccarregado', align:' text-center', sortable: false  },
            ]"
            :items="dadosPedidos"
            :expanded.sync="expanded"
            expand-icon="mdi-menu-down"
            item-key="pedido"
            class="elevation-0 pa-4"
            :items-per-page="-1"
            @click:row="handleRowClick"
            single-expand
            :item-class="rowClass"
            mobile-breakpoint="0"
            hide-default-footer
          >
            <template v-slot:item.idempresa="{ item }">
              <span class="text-truncate">{{  item.idempresa }} - {{  item.empresa }}</span>
            </template>

            <template v-slot:item.progresso="{ item }">
              <td width="23%">
                <v-progress-linear
                    :value="item.perccarregado"
                    :color="progressRowClass(item)"
                    class="ma-0"
                    striped
                    background-opacity="0.5"
                    background-color="grey"
                    height="18px"
                    rounded
                >
                  <strong>{{ !item.perccarregado ? 0 : item.perccarregado  }}%</strong>
                </v-progress-linear>
              </td>
            </template>

            <template v-slot:item.percseparar="{ item }">
              <td width="10%" class="text-center">
                <v-chip :class="item.percseparar==100 ? 'success' : item.percseparar>0 ? 'warning' : 'error'" small> <strong>{{ !item.percseparar ? (0).toFixed(2) : item.percseparar.toFixed(2)  }}%</strong></v-chip>
              </td>
            </template>
            <template v-slot:item.percconferido="{ item }">
              <td width="10%" class="text-center">
                <v-chip :class="item.percconferido==100 ? 'success' : item.percconferido>0 ? 'warning' : 'error'" small> <strong>{{ !item.percconferido ? (0).toFixed(2) : item.percconferido.toFixed(2)  }}%</strong></v-chip>
              </td>
            </template>
            <template v-slot:item.perccarregado="{ item }">
              <td width="10%" class="text-center">
                <v-chip :class="item.perccarregado==100 ? 'success' : item.perccarregado>0 ? 'warning' : 'error'" small> <strong>{{ !item.perccarregado ? (0).toFixed(2) : item.perccarregado.toFixed(2)  }}%</strong></v-chip>
              </td>
            </template>



            <template v-slot:expanded-item="{ }">
              <td colspan="10" class="px-2 pb-3 blue-grey darken-2">
                <v-simple-table class="table">
                  <thead class="header">
                  <tr class="cursor-normal">
                    <th nowrap>Cód. produto</th>
                    <th nowrap>Descrição produto</th>
                    <th nowrap class="text-center">Qtd. solicitada</th>
                    <th nowrap class="text-center">Qtd. atendida</th>
                    <th nowrap class="text-center">Qtd. devolvida</th>
                    <th nowrap class="text-center" width="12%">% Separado</th>
                    <th nowrap class="text-center" width="12%">% Conferido</th>
                    <th nowrap class="text-center" width="12%">% Carregado</th>
                  </tr>
                  </thead>
                  <tbody class="body text-capitalize" >
<!--                  <tr v-if="carregando2"><td colspan="100%"><v-skeleton-loader class="white" type="table-row" /></td></tr>-->
                  <tr v-for="(item, index) in dadosProdutos" :key="index" class="cursor-normal">
                    <td nowrap>{{ item.idsubproduto }}</td>
                    <td nowrap>{{ item.produto | formataTextoProdutoCd }}</td>
                    <td nowrap class="text-center">{{ item.qtdsolicitado  }}</td>
                    <td nowrap class="text-center">{{ item.qtdretorno }}</td>
                    <td nowrap class="text-center">{{ item.qtddevolvido }}</td>
                    <td nowrap class="text-center"><v-chip :class="item.percseparar==100 ? 'success' : item.percseparar>0 ? 'warning' : 'error'" small> <strong>{{ !item.percseparar ? (0).toFixed(2) : item.percseparar.toFixed(2)  }}%</strong></v-chip></td>
                    <td nowrap class="text-center"><v-chip :class="item.percconferido==100 ? 'success' : item.percconferido>0 ? 'warning' : 'error'" small> <strong>{{ !item.percconferido ? (0).toFixed(2) : item.percconferido.toFixed(2) }}%</strong></v-chip></td>
                    <td nowrap class="text-center"><v-chip :class="item.perccarregado==100 ? 'success' : item.perccarregado>0 ? 'warning' : 'error'" small> <strong>{{ !item.perccarregado ? (0).toFixed(2) : item.perccarregado.toFixed(2) }}%</strong></v-chip></td>
                  </tr>
                  </tbody>
                </v-simple-table>
              </td>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-btn width="100%" text @click="dialogPedidos=false, dadosPedidos=[], expanded=[]">Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog width="1280px" v-model="dialogEntregas" persistent scrollable>
      <v-card class="success" dark>
        <v-card-title class="pr-3">
          <v-icon class="pr-2">mdi-check-bold</v-icon> Entregas Confirmadas
          <v-spacer/>
          <v-col class="py-0 px-2">
            <InputDatePicker
                :disabled="false"
                v-model="busca.dtini"
                label="Data inicial"
                :outlined="true"
                :dense="true"
            />
          </v-col>
          <v-col class="py-0 pl-2 pr-2">
            <InputDatePicker
                :disabled="false"
                v-model="busca.dtfim"
                label="Data final"
                :outlined="true"
                :dense="true"
            />
          </v-col>
          <v-btn @click="listarEntregasConfirmadas()" icon><v-icon>mdi-magnify</v-icon></v-btn>
        </v-card-title>

        <v-card-text class="px-0 pb-0">
          <v-data-table
              light
              :headers="[
                { text: 'Cód.', value: 'idempresa', sortable: false, align: 'start', class:'pr-2' },
                { text: 'Empresa', value: 'empresa', sortable: false, align: 'start', class:'pr-2' },
                { text: 'Pré-Carga', value: 'idprecarga', align:'center', sortable: false },
                { text: 'Data/Hora', value: 'data', align:'center', sortable: false  },
                { text: 'Qtd. Pedidos', value: 'qtdpedidos',align:'center', sortable: false  },
                { text: 'Detalhar', value: 'exibir',align:'center', sortable: false  },
            ]"
              :items="dadosEntregasConfirmadas"
              item-key="idprecarga"
              class="elevation-0 pa-4 cursor-normal"
              :items-per-page="-1"
              mobile-breakpoint="0"
              hide-default-footer
          >
            <template v-slot:item="{ item }">
              <tr class="cursor-normal">
                <td class="text-start">{{ item.idempresa }}</td>
                <td class="text-start">{{ item.empresa }}</td>
                <td class="text-center">{{ item.idprecarga }}</td>
                <td class="text-center">{{ item.data }}</td>
                <td class="text-center">{{ item.qtdpedidos }}</td>
                <td class="text-center"><v-btn color="success" small icon @click="dialogPedidos=true;listarPedidos(item)"><v-icon>mdi-eye-settings</v-icon></v-btn></td>
              </tr>

            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-btn width="100%" text @click="dialogEntregas=false">Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog width="1280px" v-model="dialogDivergencias" persistent scrollable>
      <v-card class="red darken-1" dark flat>
        <v-card-title class="pr-3">
          <v-icon class="pr-2">mdi-alert</v-icon> Divergências Detectadas
          <v-spacer/>
          <v-col class="py-0 px-2">
            <InputDatePicker
                :disabled="false"
                v-model="busca.dtini"
                label="Data inicial"
                :outlined="true"
                :dense="true"
            />
          </v-col>
          <v-col class="py-0 pl-2 pr-2">
            <InputDatePicker
                :disabled="false"
                v-model="busca.dtfim"
                label="Data final"
                :outlined="true"
                :dense="true"
            />
          </v-col>
          <v-btn @click="listarDivergencias()" icon><v-icon>mdi-magnify</v-icon></v-btn>
        </v-card-title>

        <v-card-text class="px-0 pb-0">
          <v-data-table
              light
              :headers="[
                { text: 'Pré-Carga', value: 'idprecarga', sortable: false, align: 'start', class:'pr-2' },
                { text: 'Qtd. Pedidos', value: 'qtdpedidos',align: 'center', sortable: false  },
                { text: 'Data/Hora', value: 'data',align: 'center', sortable: false },
                { text: 'Detalhar', value: 'exibir',align:'center', sortable: false  },
            ]"
              :items="dadosDivergencias"
              item-key="idprecarga"
              class="elevation-0 pa-4 cursor-normal"
              :items-per-page="-1"
              mobile-breakpoint="0"
              hide-default-footer
              fixed-header
          >
            <template v-slot:item="{ item }">
              <tr class="cursor-normal">
                <td class="text-start">{{ item.idprecarga }}</td>
                <td class="text-center">{{ item.qtdpedidos }}</td>
                <td class="text-center">{{ item.data }}</td>
                <td class="text-center"><v-btn color="error" icon small @click="dialogPedidos=true;listarPedidos(item)"><v-icon>mdi-eye-settings</v-icon></v-btn></td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-btn width="100%" text @click="dialogDivergencias=false">Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar.status" :timeout="snackbar.timeout">
      Erro ao atualizar pré-cargas
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="erro = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-col cols="12">
      <v-card class="primary pa-3 pt-0 white--text">
        <v-card-title class="py-3 px-1">
          Monitoramento de Pré-Cargas <v-spacer/>
          <v-btn height="32" color="error" class="black--text mr-3" :class="dadosDivergencias.length>0 ? 'pulse-btn' : ''"  @click="listarDivergencias(), dialogDivergencias=true">
            <v-icon size="32" class="mr-lg-2" :style="dadosDivergencias.length>0 ? iconStyle : ''">mdi-truck-alert</v-icon>
            <v-divider class="d-none d-lg-block" vertical />
            <v-badge color="red darken-3" :content="dadosDivergencias.length" :value="dadosDivergencias.length" offset-x="-5">
              <span class="ml-2 d-none d-lg-block" :style="dadosDivergencias.length>0 ? iconStyle : ''">divergências</span>
            </v-badge>
          </v-btn>
          <v-btn height="32" class="mr-3 success black--text" @click="listarEntregasConfirmadas()">
            <v-icon size="32" color="black" class="mr-lg-2">mdi-truck-check</v-icon>
            <v-divider class="d-none d-lg-block" vertical />
            <span class="ml-2 d-none d-lg-block">Confirmadas</span>
          </v-btn>
          <v-btn height="32" color="primary" class="rounded grey lighten-2 mr-3" @click="listarPreCargas(), listarDivergencias()">
            <v-icon size="22" class="mr-lg-2">mdi-cached</v-icon>
            <v-divider class="d-none d-lg-block" vertical />
            <span class="ml-2 d-none d-lg-block">{{ countdownText }}</span>
          </v-btn>
          <v-btn height="32" color="primary" class="rounded grey lighten-2" @click="dialogTelaCheia=true">
            <v-icon size="22" class="mr-lg-2">mdi-arrow-expand-all</v-icon>
            <v-divider class="d-none d-lg-block" vertical />
            <span class="ml-2 d-none d-lg-block">tela cheia</span>
          </v-btn>
        </v-card-title>
        <v-divider/>

        <v-card-text class="pa-3">
          <v-row>
            <v-col cols="12" class="pa-0 pb-2">
              <v-card light elevation="1">
                <v-card-title class="py-2 pt-3">
                  <v-icon size="26" class="mr-3">mdi-map-clock</v-icon> Pendente
                </v-card-title>
                <v-divider/>

                <v-card-text class="pa-5">
                  <v-row>
                    <v-col cols="12" v-if="!dadosPreCarga.Pendente">
                      <span class="mx-auto">Aguardando montagem de pré-carga CISSPoder.</span>
                    </v-col>
                    <v-col cols="12" xl="2" lg="2" md="3" sm="4" v-for="dado in dadosPreCarga.Pendente" :key="dado.idprecarga" class="pa-2">
                      <v-tooltip open-delay="50000" bottom color="white" content-class='custom-tooltip'>
                        <template v-slot:activator="{ on }">
                          <span v-on="on">
                            <v-card class="pa-3 progress-card" light @click="listarPedidos(dado)">
                              <v-progress-linear
                                  value="0"
                                  color="primary"
                                  class="progress-background"
                                  height="100%"
                              ></v-progress-linear>
                              <div class="progress-content">
                                <div class="truck-icon">
                                  <v-icon color="white">mdi-truck</v-icon>
                                  <h4> 0/{{ dado.qtdpedidos }}</h4>
                                </div>

                                <h3> ID: {{ dado.idprecarga }}</h3>
                                <h5> {{ dado.data }}</h5>
                              </div>
                            </v-card>
                          </span>
                        </template>
                        <v-card elevation="0" style="min-width: 360px" class="pa-1">
                          <v-card flat class="primary" dark elevation="7">
                            <v-card-title class="text-h6 py-2">Pedidos</v-card-title>
                            <v-divider />
                            <v-card-text class="white--text py-4">
                              <v-row v-for="n in 10" :key="n" class="align-center">
                                <v-col cols="12" md="6" class="py-1 pb-n1">
                                  <v-icon class="mr-1" size="17">mdi-alert-circle</v-icon>
                                  Pedido: {{ n * 120 }}
                                </v-col>
                                <v-col cols="12" md="6" class="py-1">
                                  <v-progress-linear
                                      :value="n * 8 + 3"
                                      color="red"
                                      class="ma-0"
                                      striped
                                      background-opacity="0.2"
                                      background-color="white"
                                      height="14px"
                                      rounded
                                  >
                                    <strong>{{ n * 8 + 3 }}%</strong>
                                  </v-progress-linear>
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </v-card>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12" class="pa-0 py-1">
              <v-card light elevation="1">
                <v-card-title class="py-2 pt-3">
                  <v-icon size="26" class="mr-3">mdi-cog-play</v-icon> Produção
                </v-card-title>
                <v-divider/>

                <v-card-text class="pa-5">
                  <v-row>
                    <v-col cols="12" v-if="!dadosPreCarga.Producao">
                      <span class="mx-auto">Nenhuma pré-carga em produção no momento.</span>
                    </v-col>
                    <v-col cols="12" xl="2" lg="2" md="3" sm="4" v-for="dado, n in dadosPreCarga.Producao" :key="dado.idprecarga" class="pa-2">
                      <v-tooltip open-delay="50000" bottom color="white" content-class='custom-tooltip'>
                        <template v-slot:activator="{ on }">
                          <span v-on="on">
                            <v-card class="pa-3 progress-card" light @click="listarPedidos(dado)">
                              <v-progress-linear
                                  :value="(n+1)*22"
                                  color="primary"
                                  class="progress-background"
                                  height="100%"
                              ></v-progress-linear>
                              <div class="progress-content">
                                <div class="truck-icon">
                                  <v-icon color="white">mdi-truck</v-icon>
                                  <h4>0/{{dado.qtdpedidos}}</h4>
                                </div>

                                <h3> ID: {{ dado.idprecarga }}</h3>
                                <h5> {{ dado.data }}</h5>
                              </div>
                            </v-card>
                          </span>
                        </template>
                        <v-card elevation="0" style="min-width: 360px" class="pa-1">
                          <v-card flat class="primary" dark elevation="7">
                            <v-card-title class="text-h6 py-2">Pedidos</v-card-title>
                            <v-divider />
                            <!--                    <v-card-text class="white&#45;&#45;text py-2">-->
                            <!--                      <div :key="n" v-for="n in 10">-->
                            <!--                        <div class="float-left">-->
                            <!--                          <div class="py-1">-->
                            <!--                            <v-icon class="mr-1" size="20">mdi-alert-outline</v-icon>Pedido: {{ n*120 }}-->
                            <!--                          </div>-->
                            <!--                        </div>-->
                            <!--                        <div class="text-right">-->
                            <!--                          <v-progress-linear :value="n*8+3" color="red" class="ma-0" striped background-opacity="0.2" background-color="white">-->
                            <!--                            <strong>{{ n*8+3 }}%</strong>-->
                            <!--                          </v-progress-linear>-->
                            <!--                        </div>-->
                            <!--                      </div>-->
                            <!--                    </v-card-text>-->
                            <v-card-text class="white--text py-4">
                              <v-row v-for="n in 10" :key="n" class="align-center">
                                <v-col cols="12" md="6" class="py-1 pb-n1">
                                  <v-icon class="mr-1" size="17">mdi-alert-circle</v-icon>
                                  Pedido: {{ n * 120 }}
                                </v-col>
                                <v-col cols="12" md="6" class="py-1">
                                  <v-progress-linear
                                      :value="n * 8 + 3"
                                      color="red"
                                      class="ma-0"
                                      striped
                                      background-opacity="0.2"
                                      background-color="white"
                                      height="14px"
                                      rounded
                                  >
                                    <strong>{{ n * 8 + 3 }}%</strong>
                                  </v-progress-linear>
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </v-card>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12" class="pa-0 pt-2">
              <v-card light elevation="1">
                <v-card-title class="py-2 pt-3">
                  <v-icon size="26" class="mr-3">mdi-truck-cargo-container</v-icon> Carregado
                </v-card-title>
                <v-divider/>

                <v-card-text class="pa-5">
                  <v-row>
                    <v-col cols="12" v-if="!dadosPreCarga.Produzida">
                      <span class="mx-auto">Nenhuma pré-carga carregada no momento.</span>
                    </v-col>
                    <v-col cols="12" xl="2" lg="2" md="3" sm="4" v-for="dado in dadosPreCarga.Produzida" :key="dado.idprecarga" class="pa-2">
                      <v-tooltip open-delay="50000" bottom color="white" content-class='custom-tooltip'>
                        <template v-slot:activator="{ on }">
                          <span v-on="on">
                            <v-card class="pa-3 progress-card" light @click="listarPedidos(dado)">
                              <v-progress-linear
                                  value="100"
                                  color="primary"
                                  class="progress-background"
                                  height="100%"
                              ></v-progress-linear>
                              <div class="progress-content">
                                <div class="truck-icon">
                                  <v-icon color="white">mdi-truck</v-icon>
                                  <h4>{{dado.qtdpedidos}}/{{dado.qtdpedidos}}</h4>
                                </div>

                                <h3> ID: {{ dado.idprecarga }}</h3>
                                <h5> {{ dado.data }}</h5>
                              </div>
                            </v-card>
                          </span>
                        </template>
                        <v-card elevation="0" style="min-width: 360px" class="pa-1">
                          <v-card flat class="primary" dark elevation="7">
                            <v-card-title class="text-h6 py-2">Pedidos</v-card-title>
                            <v-divider />
                            <!--                    <v-card-text class="white&#45;&#45;text py-2">-->
                            <!--                      <div :key="n" v-for="n in 10">-->
                            <!--                        <div class="float-left">-->
                            <!--                          <div class="py-1">-->
                            <!--                            <v-icon class="mr-1" size="20">mdi-alert-outline</v-icon>Pedido: {{ n*120 }}-->
                            <!--                          </div>-->
                            <!--                        </div>-->
                            <!--                        <div class="text-right">-->
                            <!--                          <v-progress-linear :value="n*8+3" color="red" class="ma-0" striped background-opacity="0.2" background-color="white">-->
                            <!--                            <strong>{{ n*8+3 }}%</strong>-->
                            <!--                          </v-progress-linear>-->
                            <!--                        </div>-->
                            <!--                      </div>-->
                            <!--                    </v-card-text>-->
                            <v-card-text class="white--text py-4">
                              <v-row v-for="n in 10" :key="n" class="align-center">
                                <v-col cols="12" md="6" class="py-1 pb-n1">
                                  <v-icon class="mr-1" size="17">mdi-alert-circle</v-icon>
                                  Pedido: {{ n * 120 }}
                                </v-col>
                                <v-col cols="12" md="6" class="py-1">
                                  <v-progress-linear
                                      :value="n * 8 + 3"
                                      color="red"
                                      class="ma-0"
                                      striped
                                      background-opacity="0.2"
                                      background-color="white"
                                      height="14px"
                                      rounded
                                  >
                                    <strong>{{ n * 8 + 3 }}%</strong>
                                  </v-progress-linear>
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </v-card>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
	</v-row>
</template>

<script>
import axios from "axios";
import {mapState} from "vuex";
import InputDatePicker from "@/Components/Widgets/InputDatePicker.vue";

export default {
	name: "CdDashboard",
	components: {InputDatePicker},
	data: () => ({
    intervalId: null,
    countdown: 60,
    isDisabled: false,

    dadosPreCarga: [],
    dadosPedidos: [],
    dadosProdutos: [],
    dadosDivergencias: [],
    dadosEntregasConfirmadas: [],

    busca:{
      dtini: `${new Date(new Date().getFullYear(),new Date().getMonth() + 1,0).getFullYear()}-${
          (new Date( new Date().getFullYear(),new Date().getMonth() + 1,0).getMonth() + 1 < 10 ? "0": "") +(new Date().getMonth() + 1)}-${
          (new Date( new Date().getFullYear(),new Date().getMonth(),new Date().getDate()).getDate() < 10 ? "0": "") + (new Date().getDate())}`,
      dtfim: `${new Date(new Date().getFullYear(),new Date().getMonth() + 1,0).getFullYear()}-${
              (new Date( new Date().getFullYear(),new Date().getMonth() + 1,0).getMonth() + 1 < 10 ? "0": "") +(new Date().getMonth() + 1)}-${
              (new Date( new Date().getFullYear(),new Date().getMonth(),new Date().getDate()).getDate() < 10 ? "0": "") + (new Date().getDate())}`,
    },

    tempPreCarga: {},

    expanded: [],

    snackbar: { status: false, timeout: 1500 },

    dialogTelaCheia: false,
    dialogPedidos: false,
    dialogEntregas: false,
    dialogDivergencias: false,

    trucksFinalizados: [
      { id: 1, name: '1025', image: 'path/to/truck1.png', loading: 75 },
      { id: 2, name: '1026', image: 'path/to/truck2.png', loading: 50 },
      { id: 3, name: '8548', image: 'path/to/truck3.png', loading: 90 },
      { id: 3, name: '8548', image: 'path/to/truck3.png', loading: 70 },
      { id: 3, name: '8548', image: 'path/to/truck3.png', loading: 70 },
      { id: 3, name: '8548', image: 'path/to/truck3.png', loading: 70 },
    ],
    trucksDivergentes: [
      { id: 3, name: '8548', image: 'path/to/truck3.png', loading: 90 },
      { id: 3, name: '8548', image: 'path/to/truck3.png', loading: 70 },
    ],
    isPrimary: true
	}),
	computed: {
    ...mapState(["backendUrl", "pgLimit"]),
    iconStyle() {
      return {
        color: this.isPrimary ? '#8D1515' : 'black', // Cores primária e de erro
        transition: 'color 1s ease' // Transição suave de 1 segundo
      };
    },
    countdownText() {
      return this.isDisabled ? `Atualizando em ${this.countdown}s` : 'Atualizar Dados';
    }
  },
	methods: {
    startCountdown() {
      this.isDisabled = true;
      this.intervalId = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          this.listarPreCargas();
          this.listarDivergencias();
          clearInterval(this.intervalId);
          this.isDisabled = true;
          this.resetCountdown();
        }
      }, 1000);
    },
    resetCountdown() {
      clearInterval(this.intervalId);
      this.countdown = 60;
      this.startCountdown();
    },
    handleRowClick(item) {// toggleExpand(item)
      if (this.expanded.includes(item)) {
        this.listarProdutos(item);
        this.expanded = this.expanded.filter(i => i !== item);
      } else {
        this.listarProdutos(item);
        this.expanded = [item];
      }
    },
    handleRowClickPedidos(item) {// toggleExpand(item)
      if (this.expanded.includes(item)) {
        this.listarPedidos(item);
        this.expanded = this.expanded.filter(i => i !== item);
      } else {
        this.listarPedidos(item);
        this.expanded = [item];
      }
    },
    rowClass(item) {
      return this.expanded.includes(item) ? 'blue-grey darken-2 white--text' : '';
    },
    progressRowClass(item) {
      return !this.expanded.includes(item) ? 'primary' : 'grey darken';
    },
    startBlinking() {
      setInterval(() => {
        this.isPrimary = !this.isPrimary;
      }, 600); // Muda a cor a cada 1 segundo
    },
    listarPreCargas() {
      return axios
          .post(`${this.backendUrl}cd/entrega/domicilio/listar`, {
          })
          .then((res) => {
            this.dadosPreCarga = res.data;
            this.resetCountdown();
          })
          .catch(() =>{
          });
    },
    listarPedidos(dado) {
      this.expanded=[];
      this.tempPreCarga = dado;
      return axios
          .post(`${this.backendUrl}cd/entrega/domicilio/precarga/listar`, {
            idempresa: dado.idempresa,
            idprecarga: dado.idprecarga
          })
          .then((res) => {
            this.dadosPedidos = res.data;
            this.dialogPedidos = true;
          })
          .catch(() =>{
          });
    },
    listarProdutos(dado) {
      this.expanded=[];
      this.dadosProdutos=[];
      return axios
          .post(`${this.backendUrl}cd/entrega/domicilio/precarga/produtos/listar`, {
            idempresa: dado.idempresa,
            idprecarga: dado.idprecarga,
            idpedido: dado.pedido
          })
          .then((res) => {
            this.dadosProdutos = res.data == null ? []: res.data;
          })
          .catch(() =>{
            this.snackbar.status = true;
          });
    },
    listarDivergencias() {
      this.dadosDivergencias=[];
      return axios
          .post(`${this.backendUrl}cd/entrega/domicilio/divergencias/listar`, {
            dtini: this.busca.dtini,
            dtfim: this.busca.dtfim
          })
          .then((res) => {
            this.dadosDivergencias = res.data == null ? []: res.data;
          })
          .catch(() =>{
            this.snackbar.status = true;
          });
    },
    listarEntregasConfirmadas() {
      this.dialogEntregas=true;
      this.dadosEntregasConfirmadas=[];
      return axios
          .post(`${this.backendUrl}cd/entrega/domicilio/geral/listar`, {
            dtini: this.busca.dtini,
            dtfim: this.busca.dtfim
          })
          .then((res) => {
            this.dadosEntregasConfirmadas = res.data == null ? []: res.data;
          })
          .catch(() =>{
            this.snackbar.status = true;
          });
    },
    async init() {
      this.listarPreCargas();
      this.listarDivergencias();
    },
	},
	created() {
		this.init();
	},
  mounted() {
    this.startBlinking();
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
};
</script>

<style scoped>

.v-progress-linear strong {
  position: absolute;
  width: 100%;
  text-align: center;
  color: white;
}

.custom-tooltip {
  opacity: 1!important;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  padding: 0px!important;
}

.progress-card {
  position: relative;
  height: 100%;
}

.progress-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  border-radius: 5px;
}

.progress-content {
  position: relative;
  z-index: 2;
  text-align: center;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  height: 100%;
}

.truck-icon {
  position: absolute;
  top: 0px;
  right: 12px;
}

::v-deep .custom-padding .v-expansion-panel-content__wrap {
  padding: 0 !important;
}

@keyframes pulse {
  0%, 12.5% {
    transform: scale(1);
  }
  8.5% {
    transform: scale(1.08);
  }
  100% {
    transform: scale(1);
  }
}

.pulse-btn {
  animation: pulse 4s infinite; /* 4s duração da animação total (1s pulso + 3s pausa) */
  animation-timing-function: ease-in-out;
}

::v-deep .v-data-table__wrapper table tbody tr:hover {
  cursor: pointer;
}

::v-deep .cursor-normal {
  cursor: default !important;
}

.header th {
  background-color: #CFD8DC !important;
  height: 26px !important;
}

.body td {
  height: 40px !important;
  font-size: larger;
}

</style>